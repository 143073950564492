.background {
  background: rgb(22, 22, 22);
  background: linear-gradient(
    180deg,
    rgba(22, 22, 22, 1) 0%,
    rgba(37, 37, 37, 1) 100%
  );
  border-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.imgStyle {
  min-height: 300px;
  min-width: 300px;
  max-height: 500px;
  float: center;
}

.introContainer {
  padding-top: 30pt;
}
.intro {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  color: #fff;
  font-size: 3em;
  font-weight: 900;
}
.titleCol {
  padding-bottom: 30pt;
}
.titleList {
  margin: 0;
  padding: 0;
}
.title {
  text-align: left;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.7em;
  font-weight: 900;
  list-style-type: none;
}
.titleOne {
  color: rgba(92, 92, 92, 1);
}
.titleTwo {
  color: rgba(92, 92, 92, 0.8);
}
.titleThree {
  color: rgba(92, 92, 92, 0.6);
}
.titleFour {
  color: rgba(92, 92, 92, 0.5);
}
.titleFive {
  color: rgba(92, 92, 92, 0.4);
}

@media (max-width: 768px) {
  .title {
    text-align: center;
  }
  .intro {
    text-align: center;
  }
  .imgStyle {
    float: center;
  }
}

@media (min-width: 768px) {
  .imgStyle {
    float: right;
  }
}
