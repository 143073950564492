.portfolio-bg {
  background-color: #161616;
  color: #fff;
  padding: 80px 0 80px 0;
}

.portfolio-card {
  border: none;
  border-radius: 20px;
  cursor: pointer;
}

.card-title {
  font-size: 0.7em;
  font-weight: 500;
}

.card-text {
  /* margin-right: 40%; */
  font-size: 0.9em;
  text-transform: uppercase;
  font-weight: 800;
  text-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}

a.nav-color {
  color: #0096ff !important;
}
.modal-content {
  color: #9d9ca1;
  border-radius: 20px;
  background-color: #1c1c1e;
  white-space: pre-line;
}
.modal-image {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
}
.close {
  color: #fff;
  opacity: 100;
}
.modal-title {
  background-color: rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 5px 10px;
  color: #fff;
  font-size: 1.5em;
  font-weight: 800;
  text-transform: uppercase;
}
.modal-header {
  border-top-right-radius: 15px;
  border-top-left-radius: 15px;
  background-position-y: top;
  background-position-x: left;
  background-repeat: no-repeat;
  height: 300px;
  width: 100%;
  border: none;
}
.modal-body {
  padding: 40px;
}
.card-columns .card {
  margin-bottom: 1.25rem;
}

a {
  color: #fff;
}

a:hover {
  color: #0096ff;
}

@media (min-width: 768px) {
  .nav-color {
    border-radius: 5px;
    margin-left: 10px;
    border: 2px solid #0096ff;
    color: #0096ff !important;
  }
}
