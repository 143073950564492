.navBG {
  background-color: #161616;
  padding-top: 20px;
}
.nav-link {
  letter-spacing: 0.1em;
  font-weight: 800;
  font-size: 0.8rem;
}
.nav-link {
  cursor: pointer;
  color: rgba(0, 150, 255, 1) !important;
}

a.nav-link {
  cursor: pointer;
  color: rgba(255, 255, 255, 1) !important;
}

a.nav-link:hover {
  cursor: pointer;
  color: rgba(0, 150, 255, 1) !important;
}
