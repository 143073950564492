.about-bg {
  padding-bottom: 50px;
}

.value-prop {
  color: #0096ff;
  font-weight: 700;
  font-size: 1.5em;
  padding: 20px 0 20px 0;
}

.about {
  text-align: justify;
  text-justify: inter-word;
}
